import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  /**/
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
      /*
      {
        label: t('LP Migration'),
        href: 'https://v1exchange.pancakeswap.finance/#/migrate',
      },
*/
    ],
  },

  /*
  {
    label: t('Exchange'),
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: t('Liquidity'),
    icon: 'TradeIcon',
    href: '/liquidity',
  },
*/
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('IFO'),
    icon: 'IfoIcon',
    href: 'https://velddao.com',
  },

  /*
  {
    label: t('Tools'),
    icon: 'FarmIcon',
    items: [
      {
        label: t('Create Token'),
        href: '/create_token',
      },
      {
        label: t('AirDrop'),
        href: '/airdrop',
      },
      {
        label: t('Creat NFT'),
        href: '/creat_nft',
      },
    ],
  },
  */

  /*
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  */
  /*
  {
    label: t('Prediction (BETA)'),
    icon: 'PredictionsIcon',
    href: '/prediction',
  },
  {
    label: t('Lottery'),
    icon: 'TicketIcon',
    href: '/lottery',
    status: {
      text: t('Win').toLocaleUpperCase(),
      color: 'success',
    },
  },

 {
   label: t('Collectibles'),
   icon: 'NftIcon',
   href: '/collectibles',
 },
 {
   label: t('Team Battle'),
   icon: 'TeamBattleIcon',
   href: '/competition',
 },

 {
   label: t('Teams & Profile'),
   icon: 'GroupsIcon',
   items: [
     {
       label: t('Leaderboard'),
       href: '/teams',
     },
     {
       label: t('Task Center'),
       href: '/profile/tasks',
     },
     {
       label: t('Your Profile'),
       href: '/profile',
     },
   ],
 },
 */
  /*
  {
    label: t('Info'),
    icon: 'InfoIcon',
    href: 'https://pancakeswap.info',
  },
  {
    label: t('IFO'),
    icon: 'IfoIcon',
    href: '/ifo',
  },
  */
  /*
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Contact'),
        href: 'https://docs.pancakeswap.finance/contact-us',
      },
      {
        label: t('Voting'),
        href: '/voting',
      },
      {
        label: t('Github'),
        href: 'https://github.com/pancakeswap',
      },
      {
        label: t('Docs'),
        href: 'https://docs.pancakeswap.finance',
      },
      {
        label: t('Blog'),
        href: 'https://pancakeswap.medium.com',
      },
      {
        label: t('Merch'),
        href: 'https://pancakeswap.creator-spring.com/',
      },
    ],
  },
  */
]

export default config
